import { createRouter, createWebHistory } from 'vue-router'
import { useCookies } from "vue3-cookies";
import * as axios from 'axios';

const { cookies } = useCookies();
const dashboard   = require('./dashboard');
const pelanggan   = require('./pelanggan');
const branch      = require('./branch');
const claim       = require("./claim");
const customer_order = require("./customer_order");
const akun        = require('./akun');
const login       = require('./login');
const karyawan    = require('./karyawan');
const modules     = require('./modules');
const role        = require('./role');
const supplier    = require('./supplier');
const divisi      = require('./divisi');
const lokasi      = require('./lokasi');
const barang      = require('./barang');
const kategori    = require('./kategori');
const fifo        = require('./fifo');
const merk        = require('./merk');
const satuan            = require('./satuan');
const aktivitas_barang  = require('./aktivitas_barang');
const purchase_order    = require('./purchase_order');
const mata_uang   = require('./mata_uang');
const pattern     = require('./pattern');
const penomoran   = require('./penomoran');
const jurnal_memorial   = require('./jurnal_memorial');
const jurnal            = require('./jurnal');
const kas_bank_keluar   = require('./kas_bank_keluar');
const kas_bank_masuk    = require('./kas_bank_masuk');
const pembelian_luar_negri   = require('./pembelian_luar_negri');
const master_mph        = require('./master_mph');
const master_mpp        = require('./master_mpp');
const armada            = require('./armada');
const hutang_lain       = require('./hutang_lain');
const jasa              = require('./jasa');
const sales_order       = require('./sales_order');
const piutang_lain      = require('./piutang_lain');
const log               = require('./log');
const buku_besar        = require('./buku_besar');
const kas_bank_harian        = require('./kas_bank_harian');
const neraca            = require('./neraca');
const laba_rugi         = require('./laba_rugi');
const piutang_dagang    = require('./piutang_dagang');
const hutang_dagang     = require('./hutang_dagang');
const pencairan_cek_terima    = require('./pencairan_cek_terima');
const pencairan_cek_keluar    = require('./pencairan_cek_keluar');
const penjualan_distributor         = require('./penjualan_distributor');
const master_filter    = require('./master_filter');
const formula_bongkar_muat    = require('./formula_bongkar_muat');
const laporan_biaya_muat  = require('./laporan_biaya_muat');
const master_diskon    = require('./master_diskon');
const retur_pembelian  = require('./retur_pembelian');
const nota_dk_piutang  = require('./nota_dk_piutang');
const nota_dk_hutang   = require('./nota_dk_hutang');
const aktiva_tetap     = require('./aktiva_tetap');
const pembelian_aktiva     = require('./pembelian_aktiva');
const penjualan_aktiva     = require('./penjualan_aktiva');
const retur_penjualan  = require('./retur_penjualan');
const import_data  = require('./import_data');
const laporan_piutang_dagang  = require('./laporan_piutang_dagang');
const laporan_piutang_lain  = require('./laporan_piutang_lain');
const laporan_piutang_karyawan  = require('./laporan_piutang_karyawan');
const laporan_hutang_dagang  = require('./laporan_hutang_dagang');
const laporan_hutang_lain = require('./laporan_hutang_lain');
const laporan_sisa_plafond = require('./laporan_sisa_plafond');
const laporan_penjualan  = require('./laporan_penjualan');
const laporan_pembelian = require("./laporan_pembelian");
const penyusutan_aktiva  = require('./penyusutan_aktiva');
const penyusutan_biaya   = require('./penyusutan_biaya');
const penyusutan_asuransi    = require('./penyusutan_asuransi');
const pencairan_card_terima  = require('./pencairan_card_terima');
const asuransi        = require('./asuransi');
const biaya           = require('./biaya');
const pembelian_dalam_negri           = require('./pembelian_dalam_negri');
const penjualan_retail    = require('./penjualan_retail');
const pembelian_asuransi    = require('./pembelian_asuransi');
const biaya_dimuka    = require('./biaya_dimuka');
const permintaan_mutasi    = require('./permintaan_mutasi');
const mutasi    = require('./mutasi');
const koreksi_plus  = require('./koreksi_plus');
const koreksi_minus  = require('./koreksi_minus');
const penyelesaian_claim = require("./penyelesaian_claim")
const vulkanisir = require("./vulkanisir");
const proses_vulkanisir = require("./proses_vulkanisir");
const penyelesaian_vulkanisir = require("./penyelesaian_vulkanisir");
const mutasi_alokasi    = require("./mutasi_alokasi");
const laporan_stok_barang    = require("./laporan_stok_barang");
const rakit_barang = require("./rakit_barang");
const urai_barang = require("./urai_barang");
const laporan_konsistensi_data = require("./laporan_konsistensi_data");
const proses_closing = require("./proses_closing");
const hutang_dagang_ln = require("./hutang_dagang_ln");
const tutup_po = require("./tutup_po");
const laporan_cek_card = require("./laporan_cek_card");
const tutup_so = require("./tutup_so");
const uang_muka = require("./uang_muka");
const laporan_penerimaan_piutang = require("./laporan_penerimaan_piutang");
const laporan_pembayaran_hutang = require("./laporan_pembayaran_hutang");
const laporan_po  = require("./laporan_po");
const laporan_aktivitas_barang  = require("./laporan_aktivitas_barang");
const laporan_so  = require("./laporan_so");
const laporan_pembayaran_hd = require("./laporan_pembayaran_hd");
const list_export_penjualan = require("./list_export_penjualan");
const list_export_pembelian  = require("./list_export_pembelian");
const list_import_pembelian   = require('./list_import_pembelian');
const list_import_penjualan   = require('./list_import_penjualan')
const client                  = require('./client')
const master_import_pembelian = require('./master_import_pembelian');
const master_import_penjualan = require('./master_import_penjualan');
const generate_nomer_faktur   = require('./generate_nomer_faktur');
const kas_keluar   = require('./kas_keluar');
const query_data   = require('./query_data');
const batal_cek_terima   = require('./batal_cek_terima');
const laporan_jurnal   = require('./laporan_jurnal');
const approval_order   = require('./approval_order');
const export_efaktur   = require('./export_efaktur');

const routes = [
  claim.list,
  claim.show,
  customer_order.list,
  customer_order.show,
  dashboard.data,
  pelanggan.list,
  pelanggan.show,
  branch.list,
  branch.show,
  akun.list,
  akun.show,
  login.post,
  karyawan.list,
  karyawan.show,
  modules.list,
  modules.show,
  role.group,
  role.group_show,
  role.user,
  role.user_show,
  role.user_customer,
  role.user_customer_show,
  role.group_cabang,
  role.group_cabang_show,
  role.user_edit_password,
  role.administrator,
  role.administrator_show,
  supplier.list,
  supplier.show,
  divisi.list,
  divisi.show,
  lokasi.list,
  lokasi.show,
  barang.list,
  barang.show,
  kategori.list,
  kategori.show,
  fifo.list,
  fifo.show,
  merk.list,
  merk.show,
  satuan.list,
  satuan.show,
  aktivitas_barang.list,
  aktivitas_barang.show,
  purchase_order.list,
  purchase_order.show,
  mata_uang.list,
  mata_uang.show,
  pattern.list,
  pattern.show,
  penomoran.list,
  penomoran.show,
  jurnal_memorial.list,
  jurnal_memorial.show,
  jurnal.list,
  jurnal.show,
  kas_bank_keluar.list,
  kas_bank_keluar.show,
  kas_bank_masuk.list,
  kas_bank_masuk.show,
  pembelian_luar_negri.list,
  pembelian_luar_negri.show,
  master_mph.list,
  master_mph.show,
  master_mpp.list,
  master_mpp.show,
  armada.list,
  armada.show,
  hutang_lain.list,
  hutang_lain.show,
  jasa.list,
  jasa.show,
  sales_order.list,
  sales_order.show,
  piutang_lain.list,
  piutang_lain.show,
  log.list,
  buku_besar.list,
  kas_bank_harian.list,
  neraca.list,
  laba_rugi.list,
  piutang_dagang.list,
  piutang_dagang.show,
  piutang_lain.list,
  piutang_lain.show,
  hutang_dagang.list,
  hutang_dagang.show,
  hutang_lain.list,
  hutang_lain.show,
  penjualan_distributor.list,
  penjualan_distributor.show,
  pencairan_cek_terima.list,
  pencairan_cek_terima.show,
  pencairan_cek_keluar.list,
  pencairan_cek_keluar.show,
  master_filter.list,
  master_filter.show,
  formula_bongkar_muat.list,
  formula_bongkar_muat.show,
  master_diskon.list,
  master_diskon.show,
  retur_pembelian.list,
  retur_pembelian.show,
  nota_dk_piutang.list,
  nota_dk_piutang.show,
  nota_dk_hutang.list,
  nota_dk_hutang.show,
  aktiva_tetap.list,
  aktiva_tetap.show,
  pembelian_aktiva.list,
  pembelian_aktiva.show,
  penjualan_aktiva.list,
  penjualan_aktiva.show,
  retur_penjualan.list,
  retur_penjualan.show,
  import_data.list,
  laporan_piutang_dagang.list,
  laporan_piutang_lain.list,
  laporan_piutang_karyawan.list,
  laporan_hutang_dagang.list,
  laporan_hutang_lain.list,
  laporan_sisa_plafond.list,
  laporan_penjualan.list,
  laporan_biaya_muat.list,
  penyusutan_aktiva.list,
  penyusutan_aktiva.show,
  penyusutan_biaya.list,
  penyusutan_biaya.show,
  penyusutan_asuransi.list,
  penyusutan_asuransi.show,
  pencairan_card_terima.list,
  pencairan_card_terima.show,
  asuransi.list,
  asuransi.show,
  biaya.list,
  biaya.show,
  pembelian_dalam_negri.list,
  pembelian_dalam_negri.show,
  penjualan_retail.list,
  penjualan_retail.show,
  pembelian_asuransi.list,
  pembelian_asuransi.show,
  biaya_dimuka.list,
  biaya_dimuka.show,
  permintaan_mutasi.list,
  permintaan_mutasi.show,
  mutasi.list,
  mutasi.show,
  koreksi_plus.list,
  koreksi_plus.show,
  koreksi_minus.list,
  koreksi_minus.show,
  penyelesaian_claim.list,
  penyelesaian_claim.show,
  vulkanisir.list,
  vulkanisir.show,
  proses_vulkanisir.list,
  proses_vulkanisir.show,
  penyelesaian_vulkanisir.list,
  penyelesaian_vulkanisir.show,
  mutasi_alokasi.list,
  mutasi_alokasi.show,
  laporan_stok_barang.list,
  rakit_barang.list,
  rakit_barang.show,
  urai_barang.list,
  urai_barang.show,
  laporan_konsistensi_data.list,
  proses_closing.list,
  hutang_dagang_ln.list,
  hutang_dagang_ln.show,
  tutup_po.list,
  tutup_po.show,
  laporan_cek_card.list,
  tutup_so.list,
  tutup_so.show,
  uang_muka.list,
  uang_muka.show,
  laporan_penerimaan_piutang.list,
  laporan_pembayaran_hutang.list,
  laporan_pembelian.list,
  laporan_po.list,
  laporan_aktivitas_barang.list,
  laporan_so.list,
  laporan_pembayaran_hd.list,
  list_export_penjualan.list,
  list_export_penjualan.show,
  list_export_pembelian.list,
  list_export_pembelian.show,
  list_import_pembelian.list,
  list_import_pembelian.show,
  list_import_penjualan.list,
  list_import_penjualan.show,
  client.list,
  client.show,
  master_import_pembelian.list,
  master_import_penjualan.list,
  generate_nomer_faktur.list,
  kas_keluar.list,
  kas_keluar.show,
  query_data.list,
  batal_cek_terima.list,
  batal_cek_terima.show,
  laporan_jurnal.list,
  approval_order.list,
  approval_order.show,
  export_efaktur.list
];

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})


router.beforeEach(async(routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  // var now = Math.floor(new Date().getTime() / 1000);
  

  // var dataRefeshExpire = parseInt(cookies.get('refresh_expire'));
  // if (dataRefeshExpire < now) {console.log(
  //   " dataCookie.refresh_expire lebih kecil dari now",
  //   "expire token = ",
  //   dataRefeshExpire,
  //   "now = ",
  //   now
  // );
  //   //cookies.remove("refresh_token");
  // }
  // console.log('cookies.get("token") routers', cookies.get("token"));

  if(routeTo.path == "/login"){
    const url = `${process.env.VUE_APP_ROOT_API}/authentication/mode`;
    const config_login = await axios({method:'GET',url: url}).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });

    window.mode_login = config_login.data;

  }

  if (!authRequired) {
   
    if ((cookies.isKey("refresh_token") == true && cookies.get('refresh_token') !== null) && routeTo.path == "/login") {
      next({ name: "dashboard" });
    } else {
      next();
    }
  } else {
    if (cookies.isKey("refresh_token") == true && cookies.get('refresh_token') !== null) {
      if (routeTo.path == "/login") {
        next({ name: "dashboard" });
      } else {
        next();
      }
    } else {
      next({ name: "login" , query: { redirectFrom: routeTo.fullPath } });
    }
  }
})

export default router
