import * as axios from 'axios';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const BASE_URL = process.env.VUE_APP_ROOT_API;


function login(email, password, type) {
  console.log("BASE_URL", BASE_URL);

  return new Promise((resolve, reject) => {
    const url = `${BASE_URL}/authentication`;
    var data = JSON.stringify({
        "email": email,
        "password": password,
        "type": type
    });
    var config = {
      method: "post",
      url: url,
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config).then(function (response) {
        resolve(response);
    }).catch(function (error) {
        reject(error.response);
    });
  })
}

async function updateToken(token){
  let upd_token= token;
  const url = `${BASE_URL}/authentication`;
  let ref_token = cookies.get('refresh_token');
  var config2 = {
    method: "get",
    url: url,
    timeout: 10000,
    headers: {
      'Authorization': 'Bearer ' + token,
      'token': token,
      'refreshtoken': ref_token,
      'tahun': new Date().getFullYear(),
    },
  };
  
  var newToken = await axios(config2).then((response) => response)
  .catch(function (error) {

    Error(error.response);
    // var isInvalidToken = Error(error.response);

    // if(isInvalidToken == false){
    //   reject(error.response);
    // }
  });

  upd_token = newToken.data.data.newToken.token;
    

  if (newToken.data.data.newToken.token) {
    cookies.keys().forEach(cookie=> cookie == 'token' ? cookies.remove(cookie, '/', process.env.VUE_APP_DOMAIN) : '');

    cookies.set('token', newToken.data.data.newToken.token, '1d', '/',
      process.env.VUE_APP_DOMAIN, 
      process.env.VUE_APP_PRODUCTION == 1, 
      process.env.VUE_APP_PRODUCTION == 1 ? 'None': 'Lax'
    )
  }
  if (newToken.data.data.newRefreshToken.token) {
    cookies.keys().forEach(cookie=> cookie == 'refresh_token' ? cookies.remove(cookie, '/', process.env.VUE_APP_DOMAIN) : '');

    cookies.set('refresh_token', newToken.data.data.newRefreshToken.token, '1d', '/',
      process.env.VUE_APP_DOMAIN, 
      process.env.VUE_APP_PRODUCTION == 1, 
      process.env.VUE_APP_PRODUCTION == 1 ? 'None': 'Lax'
    )
  }
  // console.log('isi update token :', upd_token)
  return upd_token;
}

function Error(response){
  if (response) {
    if (response.data?.display_message == "Invalid Token" || response.data?.display_message == "error system" || response.data.data == "invalid_ref_token"){
      // cookies.remove("token");
      // cookies.remove("expire");
      // cookies.remove("refresh_token");
      // cookies.remove("refresh_expire");
			// cookies.keys().forEach(cookie=>cookies.remove(cookie, "/", ""));
			cookies.keys().forEach(cookie=>cookies.remove(cookie, '/', process.env.VUE_APP_DOMAIN));

      response.data.data == "invalid_ref_token" ? window.location.href = "/login": '';

      return true
    }
  }

  return false;
}

/*function getFeed(path_url, token) {
    return new Promise((resolve, reject) => {

        var config = {
            method: 'get',
            url: BASE_URL + path_url,
            timeout: 5000,
            headers: { 
              'Authorization': 'Bearer ' + token
            }
          };
          axios(config).then(function (response) {
            resolve(response);
          }).catch(function (error) {
            reject(error.response);
        });
    })
}*/
async function getFeed(path_url, token, timer=50000) {
  token = await updateToken(token);
  // token = newToken.length > 0 ? newToken : token;
  let tahun = cookies.get('tahun')
  // console.log('dari get: ', cookies.get('refresh_token'))

  return new Promise((resolve, reject) => {
    var config = {
      method: 'get',
      url: BASE_URL + path_url,
      timeout: timer,
      headers: { 
        'Authorization': 'Bearer ' + token,
        'refreshtoken':  cookies.get('refresh_token'),
        'tahun': tahun,
      }
    };
    axios(config).then(response => {
      resolve(response);
    }).catch(function (error) {
      var isInvalidToken = Error(error.response);

      if(isInvalidToken == false){
        reject(error.response);
      }
    });
  })
}
async function PostFeed(path_url, token, data, method, timer=10000) {
    token = await updateToken(token);

    let tahun = cookies.get('tahun')
    let user_level = Number(cookies.get('level'))
    return new Promise((resolve, reject) => {
      var config = {
        method: method,
        url: BASE_URL + path_url,
        timeout: timer,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          'refreshtoken': cookies.get("refresh_token"),
          tahun: tahun,
        },
        data: data,
      };

      if(tahun != new Date().getFullYear() && user_level != 1) {
        resolve({data:{display_message:'Lokasi Data lama tidak boleh diubah', message:'Failed'}})
      }else{
        axios(config).then(function (response) {
          resolve(response);
        }).catch(function (error) {
          var isInvalidToken = Error(error.response);

          if (isInvalidToken == false) {
            reject(error.response);
          }
        });
      }
      
    })
}
async function getDelete(path_url, token) {
  token = await updateToken(token);

  let tahun = cookies.get('tahun')
  let user_level = Number(cookies.get('level'))

 console.log('dari del: ', tahun)

  return new Promise((resolve, reject) => {
    var config = {
      method: "delete",
      url: BASE_URL + path_url,
      timeout: 10000,
      headers: {
        Authorization: "Bearer " + token,
        refreshtoken: cookies.get("refresh_token"),
        tahun: tahun,
      },
    };

    if(tahun != new Date().getFullYear() && user_level != 1) {
      resolve({data:{display_message:'Lokasi Data lama tidak boleh diubah', message:'Failed'}})
    }else{
      axios(config).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        var isInvalidToken = Error(error.response);

        if (isInvalidToken == false) {
          reject(error.response);
        }
      });
    }
  })
}
async function PostLog(path_url, token, data, method) {
  token = await updateToken(token);

  let tahun = cookies.get('tahun')
  let user_level = Number(cookies.get('level'))
  return new Promise((resolve, reject) => {

    var config = {
      method: method,
      url: BASE_URL + path_url,
      timeout: 10000,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        refreshtoken: cookies.get("refresh_token"),
        tahun: tahun,
      },
      data: data,
    };
    
    if(tahun != new Date().getFullYear() && user_level != 1) {
      resolve({data:{display_message:'Lokasi Data lama tidak boleh diubah', message:'Failed'}})
    }else{
      axios(config).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        var isInvalidToken = Error(error.response);

        if (isInvalidToken == false) {
          reject(error.response);
        }
      });
    }
  })
}
async function Report(path_url, token, filename, ext){
  token = await updateToken(token);
  let tahun = cookies.get('tahun')

  return new Promise((resolve, reject) => {
    var config = {
      method: "GET",
      url: BASE_URL + path_url,
      timeout: 10000,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Disposition": "attachment; filename=" + filename,
        "Content-Type": "application/" + ext,
        refreshtoken: cookies.get("refresh_token"),
        tahun: tahun,
      },
      responseType: "blob",
    };
      axios(config).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        var isInvalidToken = Error(error.response);

        if (isInvalidToken == false) {
          reject(error.response);
        }
    });
  })
}
async function PostExport(path_url, token, data, method, timer=10000) {
  token = await updateToken(token);

  let tahun = cookies.get('tahun')
  let user_level = Number(cookies.get('level'))
  return new Promise((resolve, reject) => {
    var config = {
      method: method,
      url: path_url,
      timeout: timer,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        'refreshtoken': cookies.get("refresh_token"),
        tahun: tahun,
      },
      data: data,
    };

    axios(config).then(function (response) {
      resolve(response);
    }).catch(function (error) {
      var isInvalidToken = Error(error.response);
      if (isInvalidToken == false) {
        reject(error.response);
      }
    });
  })
}
function getTokenOtp(email, domain, type) {
  return new Promise((resolve, reject) => {
    var config = {
      method: 'get',
      url: `${BASE_URL}/authentication/generate_otp/?email=${email}&domain=${domain}&type=${type}`,
      timeout: 50000,
    };
    axios(config).then(response => {
      resolve(response);
    }).catch(function (error) {
      var isInvalidToken = Error(error.response);

      if(isInvalidToken == false){
        reject(error.response);
      }
    });
  })
}
function verifyOtp(token, secret) {
  return new Promise((resolve, reject) => {
    const url =  `${BASE_URL}/authentication/verify_otp`;
    var data = JSON.stringify({
        "tokenOtp": token,
        "secretOtp": secret
    });
    var config = {
      method: "post",
      url: url,
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config).then(function (response) {
        resolve(response);
    }).catch(function (error) {
        resolve(error.response);
    });
  })
}
function getQrOtp(email, domain, type) {
  return new Promise((resolve, reject) => {
    var config = {
      method: 'get',
      url: `${BASE_URL}/authentication/qr_otp/?email=${email}&domain=${domain}&type=${type}`,
      timeout: 50000,
    };
    axios(config).then(response => {
      resolve(response);
    }).catch(function (error) {
      var isInvalidToken = Error(error.response);

      if(isInvalidToken == false){
        reject(error.response);
      }
    });
  })
}
export { login, getFeed, PostFeed, getDelete, PostLog, Report, updateToken, PostExport, getTokenOtp, verifyOtp, getQrOtp }
